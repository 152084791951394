<template>
    <Modal class="register-modal" :modelValue="show" @update:modelValue="$emit('close')">
        <RegisterForm :isModal="true" @showLoginModal="$emit('showModal', 'login')" @registered="$emit('save')"/>
    </Modal>
</template>

<script>
import Modal from '@/components/elements/Modal'
import RegisterForm from '@/components/auth/RegisterForm'

export default({
    props: ['show'],
    components: {
        Modal,
        RegisterForm
    },
    setup() {
        
    },
})
</script>

<style scoped lang="scss">
.register-modal ::v-deep .modal{
    width: 500px;
    padding: 0;
}
</style>