<template>
    <Modal class="forgot-password-modal" v-model="show">
        <ForgotPasswordForm :isModal="true" @showModal="$emit('showModal')"/>
    </Modal>
</template>

<script>
import Modal from '@/components/elements/Modal'
import ForgotPasswordForm from '@/components/auth/ForgotPasswordForm'

export default({
    props: ['show'],
    components: {
        Modal,
        ForgotPasswordForm
    },
    setup() {
        
    },
})
</script>

<style scoped>
.forgot-password-modal ::v-deep .modal{
    width: 500px;
    padding: 0;
}
.forgot-password-modal ::v-deep .auth-form {
    min-height: auto;
}
@media (max-width: 768px) {
  .forgot-password-modal ::v-deep .modal{
    width: 100%;
  }
}
</style>
