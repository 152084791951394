<template>
    <Modal class="login-modal" :modelValue="show" @update:modelValue="$emit('close')">
        <LoginForm :isModal="true" @showModal="$emit('showModal')" @logined="$emit('save')"/>
    </Modal>
</template>

<script>
import Modal from '@/components/elements/Modal'
import LoginForm from '@/components/auth/LoginForm'

export default({
    props: ['show'],
    components: {
        Modal,
        LoginForm
    },
    setup() {
        
    },
})
</script>

<style scoped>
.login-modal ::v-deep .modal{
    width: 500px;
    padding: 0;
    height: 365px;
}
@media (max-width: 768px) {
  .login-modal ::v-deep .modal{
    width: 100%;
  }
}
</style>
