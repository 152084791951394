<template>
    <Form :isModal="isModal" :data="data" @submit="submit" @showModal="$emit('showModal')">
        <template #success>
            Your password reset request has been sent. 
            If email address you provided exists in our database you will 
            receive an email with password reset link and instructions
        </template>
    </Form>
</template>


<script>
import Form from '@/components/auth/Form'
import { reactive } from '@vue/reactivity'
import api from '@/services/auth/auth.service.js'
import { getValidationError, clearValidationError } from '@/helpers/objects'

export default {
    props: ['isModal'],
    components: {
        Form
    },
    setup() {

        const data = reactive({
            step: 'form',
            title: 'Reset new password',
            inputs: [
                { label: 'Email', placeholder: 'james.smith@mail.com', value: '', type: 'text', key: 'email' },
            ],
            link: {
                title: 'BACK to LOGIN',
                href: '/login',
            },
            btn: {
                title: 'RESET PASSWORD',
                type: 'primary',
                icon: 'fas fa-redo',
                loading: false,
            }
        })

        async function submit(payload) {
            clearValidationError(data.inputs)
            const result = await api.resetPassword(payload)
            data.btn.loading = false
            
            if(result.errors) {
                getValidationError(result.errors, data.inputs)
                return
            }
            showSuccesssStep()
        }

        function showSuccesssStep() {
            data.step = 'success'

            const btnSuccesStep = {
                title: 'Back to login',
                loading: false,
                icon: 'fas fa-arrow-left',
                href: '/login',
                type: 'primary'
            }
            data.btn = btnSuccesStep
        }


        return {
            data,
            submit
        }
    }
}
</script>
